import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import { ArticleBody } from "../components/article/ArticleBody";

type Props = {
  location: Location;
  pageContext: {
    contentfulId: string;
  };
  data: {
    article: GatsbyTypes.ContentfulArticle;
  };
};

const Article: React.FC<Props> = ({ location, data: { article } }) => (
  <Layout
    title={article.title}
    ogpDescription={article.ogpDescription}
    ogpImageUrl={article.ogpImage && `https:${article.ogpImage.fixed.src}`}
    ogpUrl={`https://about.refcome.com${location.pathname}/`}
    ogpTitle={article.ogpTitle}
  >
    <ArticleBody article={article} />
  </Layout>
);

export default Article;

export const ArticleQuery = graphql`
  query Article($contentfulId: String!) {
    article: contentfulArticle(contentful_id: { eq: $contentfulId }) {
      id
      contentful_id
      date
      title
      ogpDescription
      ogpImage {
        fixed(width: 1200) {
          src
        }
      }
      ogpTitle
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            description
            file {
              url
              contentType
            }
          }
        }
      }
      category {
        id
        name
        abbreviation
      }
    }
  }
`;
