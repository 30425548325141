import * as React from "react";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";

interface Props {
  href: string;
  title: string;
}

export const SnsShare: React.FC<Props> = ({ href, title }) => {
  const encodeHref = encodeURI(href);

  return (
    <>
      <style jsx>{`
        .sns :global(.react-share__ShareButton) {
          display: inline-block;
          margin-right: 5px;
        }
      `}</style>
      <div className="sns">
        <FacebookShareButton url={`https://about.refcome.com${encodeHref}`}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={`https://about.refcome.com${encodeHref}/`} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
    </>
  );
};
