import React from "react";
import { Color, Variables } from "../designToken";
import DateText from "../dateText";
import StyledLink from "../styledLink";

type Props = {
  categoryName: string;
  title: string;
  createdAt: string;
  slug: string;
  category: {
    name: string;
    slug: string;
  };
};

export const Header: React.FC<Props> = (props) => {
  let date = props.createdAt.split("T")[0];
  date = date.replace(/-/g, ".");

  return (
    <>
      <style jsx>{`
        .header {
          margin-bottom: 30px;
        }
        .category {
          border: solid 1px ${Color.orange};
          padding: 2px 20px;
          font-weight: 500;
          font-size: 20px;
          display: inline-block;
          border-radius: 5px;
          margin-bottom: 20px;
          color: ${Color.orange};
        }
        h1 {
          font-size: 32px;
        }
        .subTextWrapper {
          font-size: 14px;
          margin-bottom: 20px;
        }
        .header :global(.date) {
          display: inline-block;
          margin-right: 10px;
          color: ${Color.lightText};
          border-right: solid 1px ${Color.lightText};
          line-height: 1;
          padding-right: 10px;
          font-size: 16px;
          letter-spacing: 1px;
        }
        .header .categoryJp {
          display: inline-block;
        }
        @media (${Variables.breakPoint.sp}) {
          h1 {
            font-size: 24px;
            line-height: 1.5;
          }
          .category {
            font-size: 18px;
          }
          .subTextWrapper {
            margin-top: 5px;
          }
        }
      `}</style>
      <div className="header">
        <div className="category">{props.categoryName}</div>
        <h1>{props.title}</h1>
        <div className="subTextWrapper">
          <DateText dateTime={props.createdAt} />
          <span className="categoryJp">
            <StyledLink href={"/news/" + props.category.slug + "/1"} text={props.category.name} />
          </span>
        </div>
      </div>
    </>
  );
};
