import React from "react";
import { Variables } from "../designToken";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Body } from "./Body";

type Props = {
  article: GatsbyTypes.ContentfulArticle;
};

const CATEGORY_NAME = "news";
export const ArticleBody: React.FC<Props> = ({ article }) => {
  return (
    <>
      <style jsx>{`
        .sectionInner {
          padding-top: 150px;
          max-width: 800px;
        }
        @media (${Variables.breakPoint.sp}) {
          .sectionInner {
            padding-top: 100px;
          }
        }
      `}</style>
      <article className="section">
        <div className="sectionInner">
          <Header
            categoryName={CATEGORY_NAME}
            title={article.title}
            createdAt={article.date}
            slug={article.contentful_id}
            category={{
              name: article.category.name,
              slug: article.category.abbreviation,
            }}
          />
          <Body content={article.content} />
          <Footer
            category={{
              name: article.category.name,
              slug: article.category.abbreviation,
            }}
            sharedUrl={`/${CATEGORY_NAME}/${article.contentful_id}`}
            title={article.ogpTitle || article.title}
          />
        </div>
      </article>
    </>
  );
};
