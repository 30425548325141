import React from "react";
import { Color } from "../designToken";
import { SnsShare } from "../snsShare";
import StyledLink from "../styledLink";

type Props = {
  category: {
    name: string;
    slug: string;
  };
  sharedUrl: string;
  title: string;
};

export const Footer: React.FC<Props> = ({ category, sharedUrl, title }) => {
  return (
    <>
      <style jsx>{`
        .footer {
          margin-top: 50px;
          text-align: right;
        }
        .footer .category,
        .footer :global(.sns) {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        .footer .category {
          font-size: 14px;
          position: relative;
          top: -4px;
          color: ${Color.lightText};
          border-left: solid 1px ${Color.lightText};
          padding: 0 0 0 10px;
        }
      `}</style>
      <div className="footer">
        <SnsShare href={sharedUrl} title={title} />
        <div className="category">
          <StyledLink href={`/news/${category.slug}/1`} text={category.name} />
        </div>
      </div>
    </>
  );
};
