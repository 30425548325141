import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Color, Variables } from "../designToken";
import StyledLink from "../styledLink";
import { isChrome } from "react-device-detect";

type Props = {
  content: any;
};

export const Body: React.FC<Props> = ({ content }) => {
  const Asset = ({ children }) => {
    const isPdf = children.data.target.file.contentType === "application/pdf";
    const { url } = children.data.target.file;
    const { description } = children.data.target;

    if (isPdf) {
      return (
        <>
          <style jsx>{`
            .wrapper {
              margin: 40px auto;
            }
            .pdfWrapper {
              display: block;
              position: relative;
              width: 100%;
              padding-top: 56.25%;
            }
            .pdf {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: none;
            }
            .helpText {
              text-align: center;
              margin: 10px auto 0;
            }
          `}</style>
          <div className="wrapper">
            <div className="pdfWrapper">
              {isChrome ? (
                <iframe className="pdf" src={`${url}#navpanes=0&zoom=25`} width="100%" height="100%">
                  <p className="helpText">
                    <StyledLink isTargetBlank href={url} text="PDFをダウンロード" />
                  </p>
                </iframe>
              ) : (
                <object className="pdf" data={url} width="100%" height="100%" />
              )}
            </div>
            {!isChrome && (
              <p className="helpText">
                <StyledLink isTargetBlank href={url} text="PDFをダウンロード" />
              </p>
            )}
          </div>
        </>
      );
    }

    // @Note: PDF以外は画像として扱う
    return (
      <div className="embeddedImageWrapper">
        <img src={url} className="embeddedImage" alt="" />
        {description !== "" && description !== null && description !== undefined && (
          <span className="description">【{description}】</span>
        )}
      </div>
    );
  };

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return <Asset children={node} />;
      },
      [INLINES.HYPERLINK]: (node) => {
        const urlAndParams = node.data.uri.split("?");
        if (urlAndParams.length > 1) {
          const lastChild = urlAndParams[1].split("&").pop().split("=");
          if (lastChild[0] === "_blank" && lastChild[1] === "true") {
            const url = node.data.uri.replace(/(.*)_blank=true/, "$1");
            return <StyledLink href={url} text={node.content[0].value} isTargetBlank />;
          }
          return <StyledLink href={node.data.uri} text={node.content[0].value} />;
        }
        return <StyledLink href={node.data.uri} text={node.content[0].value} />;
      },
      [BLOCKS.HEADING_3]: (node) => {
        return (
          <h3>
            <span>{node.content[0].value}</span>
          </h3>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const paragraphList = [];
        children.forEach((child) => {
          if (toString.call(child) == "[object String]") {
            const textList = child.split(/\n/);
            const newLineTextList = [];
            textList.forEach((text, index) => {
              if (index == textList.length - 1) {
                newLineTextList.push(<>{text}</>);
                return;
              }
              newLineTextList.push(
                <>
                  {text}
                  <br />
                </>
              );
            });
            paragraphList.push(<>{newLineTextList}</>);
          } else {
            paragraphList.push(child);
          }
        });
        return <p>{paragraphList}</p>;
      },
    },
  };
  return (
    <>
      <style jsx>{`
        .mainContent :global(h2) {
          border: solid 2px ${Color.defaultText};
          border-left: none;
          border-right: none;
          padding: 15px 0;
          margin: 60px 0 30px;
          font-size: 24px;
        }
        .mainContent :global(h3) {
          position: relative;
          font-size: 21px;
          display: block;
          z-index: 1;
          margin: 60px 0 20px;
        }
        .mainContent :global(h3 span) {
          border-left: solid 5px ${Color.orange};
          position: relative;
          padding-left: 15px;
        }
        .mainContent :global(h4) {
          margin: 60px 0 20px;
          font-size: 18px;
        }
        .mainContent :global(p) {
          font-size: 16px;
          margin: 15px 0;
        }
        .mainContent :global(.embeddedImageWrapper) {
          margin: 30px 0;
          text-align: center;
        }
        .mainContent :global(.embeddedImageWrapper .embeddedImage) {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
        .mainContent :global(.embeddedImageWrapper .description) {
          font-size: 16px;
          font-weight: 500;
          margin-top: 5px;
          display: inline-block;
        }
        .mainContent :global(ul) {
          list-style-type: disc;
          padding-left: 16px;
        }
        .mainContent :global(ul li p) {
          margin: 0;
        }

        @media (${Variables.breakPoint.sp}) {
          .mainContent :global(h2) {
            margin: 40px 0 20px;
            font-size: 20px;
            line-height: 1.5;
          }
          .mainContent :global(h3) {
            margin: 40px 0 20px;
            font-size: 18px;
          }
          .mainContent :global(h4) {
            margin: 40px 0 10px;
            font-size: 16px;
          }
          .mainContent :global(p) {
            font-size: 16px;
            line-height: 1.9;
          }
          .mainContent :global(.embeddedImageWrapper .description) {
            font-size: 13px;
          }
        }
      `}</style>
      <div className="mainContent">{renderRichText(content, options)}</div>
    </>
  );
};
